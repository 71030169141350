import { StoreDoc } from '@gooduncles/gu-app-schema';
import { storeStateList } from '@gooduncles/gu-app-schema/constants';
import {
  Card,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Typography,
} from '@mui/joy';
import { FC } from 'react';
import { storeCodeRegex } from 'src/constants';
import { getStoresWithQuery } from 'src/services/storeService';

import EditableSelectField from 'src/components/atoms/EditableSelectField';
import EditableTextField from 'src/components/atoms/EditableTextField';

const validateStoreCode = async (value: string) => {
  if (!value) return;
  if (value.length !== 6) {
    throw new Error("매장코드는 'R' 또는 'X' + 5자리 숫자 또는 빈값이어야 합니다.");
  }

  const stores = await getStoresWithQuery([['storeCode', '==', value]], []);
  if (stores.length > 0) {
    throw new Error('이미 사용중인 매장코드입니다.');
  }
};

const EditableFieldForStore: FC<{
  store: StoreDoc;
  label: string;
  field: keyof StoreDoc;
  nullable?: boolean;
  validate?: (value: string) => Promise<void>;
}> = ({ label, field, store, nullable, validate }) => (
  <EditableTextField
    label={label}
    field={field}
    value={store[field]}
    collectionPath='store'
    docId={store._id}
    nullable={nullable}
    validate={validate}
  />
);

const EditableSelectFieldForStore: FC<{
  store: StoreDoc;
  label: string;
  field: keyof StoreDoc;
  options: JSX.Element[];
  disabled?: string;
}> = ({ label, field, store, options, disabled }) => (
  <EditableSelectField
    label={label}
    field={field}
    value={store[field]}
    collectionPath='store'
    docId={store._id}
    options={options}
    disabled={disabled}
  />
);

interface StoreModalProps {
  store: StoreDoc | null;
  newStoreCode: number | null;
  handleCloseModal: () => void;
}

const StoreModal: FC<StoreModalProps> = ({ store, newStoreCode, handleCloseModal }) => {
  if (!store) {
    return null;
  }
  const stateChangeable = !!(store.storeCode && store.storeCode.length === 6);

  return (
    <Modal open={!!store} onClose={handleCloseModal}>
      <ModalDialog variant='outlined' layout='fullscreen'>
        <ModalClose />
        <DialogTitle>
          <Typography>매장정보</Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: 1 }}>
          <Card>
            <EditableFieldForStore label='매장명' field='storeNickname' store={store} />
            <EditableFieldForStore
              label={`매장코드 (자동 추천: R${newStoreCode})`}
              field='storeCode'
              store={store}
              validate={(value) => {
                if (store.storeCode && store.storeCode?.match(storeCodeRegex) !== null) {
                  throw new Error('규칙에 맞게 입력된 매장코드는 수정할 수 없습니다.');
                }
                return validateStoreCode(value);
              }}
              nullable
            />
            <EditableSelectFieldForStore
              label='매장상태'
              field='state'
              store={store}
              disabled={stateChangeable ? undefined : '6자리 매장코드가 필요합니다.'}
              options={Object.entries(storeStateList).map(([state, label]) => (
                <Option key={state} value={state}>
                  {label}
                </Option>
              ))}
            />
            <Divider />
            <EditableFieldForStore label='대표' field='ownerName' store={store} />
            <EditableFieldForStore label='대표 연락처(카톡 수신자)' field='ownerTel' store={store} />
            <EditableFieldForStore label='생년월일(법인번호)' field='ownerBirthday' store={store} />
            <EditableFieldForStore label='상호명' field='businessName' store={store} />
            <EditableFieldForStore label='사업자번호' field='businessNumber' store={store} />
            <EditableFieldForStore label='사업자주소' field='businessAddress' store={store} />
          </Card>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default StoreModal;
